import { selectClientOrders } from '../../../features/orders/store/order.selectors';
import { Client } from '../../../features/clients/clients.model';
import {
  getActiveClient,
  getSelectedClient
} from '../../../features/clients/clients.selectors';
import { createSelector } from '@ngrx/store';
import { adapter, proceduresFeatureKey } from './procedure.reducer';
import {
  getActiveOrder,
  selectAllOrders
} from '../../../features/orders/store/order.selectors';
import { Order } from '../../../features/orders/orders.model';
import { selectRouteParams } from '../../core.state';
import {
  ClientProceduresTable,
  OrderProcedureRecord,
  Procedure,
  ProcedureRecord,
  ProceduresState
} from '../../../shared/procedures/procedures.model';
import {
  procedureItems,
  zones,
  iterationCount,
  regimes,
  reminderPeriods
} from '../../../shared/procedures/procedure/procedure.nomenclatures';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectProcedures = (state): ProceduresState =>
  state[proceduresFeatureKey];
export const selectAllProcedures = createSelector(selectProcedures, selectAll);
export const selectProcedureEntities = createSelector(
  selectProcedures,
  selectEntities
);

export const getOrderProcedures = createSelector(
  selectAllProcedures,
  getActiveOrder,
  (entities: ProcedureRecord[], activeOrder: Order): OrderProcedureRecord[] =>
    entities
      .filter(
        (entity) => activeOrder?.id && entity?.sk?.includes(activeOrder?.id)
      )
      .map((procedure) => ({
        ...activeOrder,
        ...procedure,
        orderId: activeOrder.id
      }))
);

export const getClientProcedures = createSelector(
  getSelectedClient,
  selectAllProcedures,
  (client: Client, entities: ProcedureRecord[]): ProcedureRecord[] =>
    entities.filter(
      (entity) =>
        entity?.pk === ClientProceduresTable.partitionKeyPrefix + client?.id
    )
);

export const getOrderClientProcedures = createSelector(
  selectClientOrders,
  getClientProcedures,
  (orders: Order[], procedures: ProcedureRecord[]): OrderProcedureRecord[] =>
    procedures
      .filter((entity) => !!orders.find((el) => entity.sk.includes(el.id)))
      .map((procedure) => {
        const order = orders.find((el) => procedure.sk.includes(el.id));
        return {
          ...order,
          ...procedure,
          orderId: order.id
        };
      })
);

export const selectShouldUpdatedClientProcedures = createSelector(
  selectProcedures,
  (state: ProceduresState): boolean => {
    const lastUpdate =
      state.lastUpdate && state.lastUpdate[state.selectedClientId];
    return !lastUpdate || (Date.now() - lastUpdate) / 1000 / 3600 > 1;
  }
);

export const getSelectedProcedure = createSelector(
  selectProcedureEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const selectedLastUpdatedClientProcedures = createSelector(
  selectProcedures,
  (state) => state.lastUpdate[state.selectedClientId]
);

export const getActiveProcedure = createSelector(
  selectProcedures,
  selectProcedureEntities,
  (state: ProceduresState, entities) =>
    entities && entities[state.selectedProcedureId]
);

export const getIsNewProcedure = createSelector(
  selectProcedures,
  (state: ProceduresState) => state.selectedProcedureId === 'new'
);

export const getProcedureItems = createSelector(
  selectProcedures,
  getOrderProcedures,
  getActiveProcedure,
  (
    state: ProceduresState,
    orderProcedures: ProcedureRecord[],
    activeProcedure: Procedure
  ): Procedure[] =>
    procedureItems.filter(
      (item) =>
        !orderProcedures.find(
          (el) => el.code === item.code && el.code !== activeProcedure?.code
        )
    )
);

export const getProcedureZones = createSelector(
  selectProcedures,
  (state: ProceduresState): Zone[] => zones as unknown as Zone[]
);

export const getProcedureIterationCnt = createSelector(
  selectProcedures,
  (state: ProceduresState): number[] => iterationCount
);

export const getProcedureRegime = createSelector(
  selectProcedures,
  (state: ProceduresState): Procedure['regime'][] => regimes
);

export const getProcedureReminderPeriods = createSelector(
  selectProcedures,
  (state: ProceduresState): Procedure['nextReminder'][] => reminderPeriods
);

export const getProceduresNotAssignedToOrder = createSelector(
  getClientProcedures,
  selectAllOrders,
  getActiveClient,
  (
    procedures: ProcedureRecord[],
    allOrders: Order[],
    activeClient: Client
  ): { procedure: ProcedureRecord; order: Order }[] =>
    procedures
      .map((procedure) => ({
        procedure,
        order: allOrders
          .filter((ord) => ord.clientId === activeClient?.id)
          .find((el) => procedure.sk.includes(el.id))
      }))
      .filter((el) => !el.order)
);
