import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Order } from '../orders.model';

export const initOrders = createAction('[Order/API] Init');

export const loadOrders = createAction(
  '[Order/API] Load Orders',
  props<{ orders: Order[] }>()
);

export const addOrder = createAction(
  '[Order/API] Add Order',
  props<{ order: Order }>()
);

export const addOrderSuccess = createAction(
  '[Order/API] Add Order success',
  props<{ order: Order }>()
);

export const addOrderFail = createAction('[Order/API] Add Order fail');

export const addEvent = createAction(
  '[Order/API] Add Event',
  props<{ order: Order }>()
);

export const updateEvent = createAction(
  '[Order/API] Update Event',
  props<{ order: Order }>()
);

export const upsertOrder = createAction(
  '[Order/API] Upsert Order',
  props<{ order: Order }>()
);

export const upsertOrderSuccess = createAction(
  '[Order/API] Upsert Order Success',
  props<{ order: Order }>()
);

export const addOrders = createAction(
  '[Order/API] Add Orders',
  props<{ orders: Order[] }>()
);

export const upsertOrders = createAction(
  '[Order/API] Upsert Orders',
  props<{ orders: Order[] }>()
);

export const updateOrder = createAction(
  '[Order/API] Update Order',
  props<{ order: Update<Order> }>()
);

export const updateOrders = createAction(
  '[Order/API] Update Orders',
  props<{ orders: Update<Order>[] }>()
);

export const deleteOrder = createAction(
  '[Order/API] Delete Order',
  props<{ order: Order }>()
);

export const deleteOrderSuccess = createAction(
  '[Order/API] Delete Order Success',
  props<{ id: string }>()
);

export const deleteOrders = createAction(
  '[Order/API] Delete Orders',
  props<{ ids: string[] }>()
);

export const clearOrders = createAction('[Order/API] Clear Orders');
export const resetOrderForm = createAction('[Order/API] Reset Order Form');
export const setActiveOrder = createAction(
  '[Order/API] Set active',
  props<{ selectedOrderId: Order['id']; dispatch?: boolean }>()
);
