import { getActiveClient } from './../../clients/clients.selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAllClients } from '../../clients/clients.selectors';
import {
  GroupedReminder,
  Reminder,
  RemindersState,
  State
} from '../reminders.model';
import { adapter, remindersFeatureKey } from './reminder.reducer';
import { selectRouteParams } from '../../../core/core.state';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectReminders = createFeatureSelector<State, RemindersState>(
  remindersFeatureKey
);
export const selectAllReminderRecords = createSelector(
  selectReminders,
  selectAll
);
export const selectReminderEntities = createSelector(
  selectReminders,
  selectEntities
);

export const selectAllReminders = createSelector(
  selectAllReminderRecords,
  selectAllClients,
  (reminderRecords, clients): Reminder[] =>
    reminderRecords.map((record) => ({
      ...record,
      clientName:
        clients.find(
          (client) => !!client && !!record && client?.id === record?.clientId
        )?.name || 'unknown'
    }))
);

export const selectLatestRemindersPerClient = createSelector(
  selectAllReminders,
  (reminders): Reminder[] => {
    let rs: Reminder[] = [];
    reminders.forEach((reminder) => {
      const clientRecord = rs.find(
        (el) =>
          el?.clientId === reminder.clientId &&
          el?.procedureCode === reminder.procedureCode
      );
      if (clientRecord) {
        if (clientRecord.date <= reminder.date) {
          rs = rs.filter((el) => el.pk !== clientRecord.pk);
          rs.push(reminder);
        }
      } else {
        rs.push(reminder);
      }
    });
    return rs;
  }
);

export const selectGroupedRemindersPerClient = createSelector(
  selectLatestRemindersPerClient,
  (reminders): GroupedReminder[] => {
    const groupedReminders: GroupedReminder[] = [];
    reminders.forEach((reminder) => {
      const clientRecord = groupedReminders.find(
        (el) =>
          el?.clientId === reminder.clientId &&
          el?.date &&
          new Date(+el?.date).setHours(0) ===
            new Date(+reminder.date).setHours(0)
      );

      if (clientRecord) {
        clientRecord.procedureCodes.push(reminder.procedureCode);
      } else {
        groupedReminders.push({
          ...reminder,
          procedureCodes: [reminder.procedureCode]
        });
      }
    });
    return groupedReminders;
  }
);

export const selectClientReminders = createSelector(
  selectGroupedRemindersPerClient,
  getActiveClient,
  (reminders, activeClient): GroupedReminder[] =>
    reminders.filter((reminder) => reminder.clientId === activeClient?.id)
);

export const selectOutdatedRemindersPerClient = createSelector(
  selectAllReminders,
  selectLatestRemindersPerClient,
  (reminders, latest): Reminder[] =>
    reminders.filter((reminder) => !latest.find((el) => el.pk === reminder.pk))
);

export const getSelectedReminder = createSelector(
  selectReminderEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);
