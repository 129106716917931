import { createAction, props } from '@ngrx/store';

export const authLoginAttempt = createAction(
  '[Auth] Login attempt',
  props<{ userName: string; password: string }>()
);

export const authLoginSuccess = createAction('[Auth] Login success');
export const authLoginFailure = createAction('[Auth] Login failure');
export const authLogout = createAction('[Auth] Logout');
