import { EntityState } from '@ngrx/entity';
import { DynamoDbOneToManyPattern } from '../../core/backend/backend.models';
import { AppState } from '../../core/core.state';
import { Client } from '../../features/clients/clients.model';
import { Order } from '../../features/orders/orders.model';

export type Zone = 'face' | 'body' | 'legs';

export type ProcedureCode =
  | 'top-lips'
  | 'areols'
  | 'whole-face'
  | 'cheekbones'
  | 'beard'
  | 'sideburns'
  | 'throat'
  | 'neck'
  | 'armpits'
  | 'stomach-line'
  | 'bikini'
  | 'shoulders'
  | 'half-hands'
  | 'stomach'
  | 'waist'
  | 'breasts'
  | 'backside'
  | 'intimate'
  | 'hands'
  | 'back'
  | 'half-legs'
  | 'legs';

export interface Procedure {
  zone: Zone;
  code: ProcedureCode;
  iterationCount: number;
  nextReminder: number;
  price?: number;
  regime?: 'static' | 'dynamic';
  intensity?: number;
  frequency?: number;
  comment?: string;
}

export interface ProcedureRecord extends DynamoDbOneToManyPattern, Procedure {
  // CLIENT#[Client.Id]
  pk: string;
  // [Order.id]#[Procedure.code]
  sk: string;
  id: string;
}

export type OrderProcedureRecord = ProcedureRecord &
  Order & { orderDate?: string; orderId: string };

export enum ClientProceduresTable {
  name = 'client-procedures',
  partitionKeyPrefix = 'CLIENT#',
  orderKeyPrefix = 'ORDER#',
  procedureKeyPrefix = 'PROCEDURE#'
}

export interface ProceduresState extends EntityState<ProcedureRecord> {
  selectedProcedureId: ProcedureRecord['pk'];
  selectedOrderId: Order['id'];
  selectedClientId: Client['id'];
  lastUpdate: Record<string, number>;
}
export interface State extends AppState {
  procedures: ProceduresState;
}
