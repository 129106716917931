import { RemindersState, ReminderRecord } from '../reminders.model';
import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ReminderActions from './reminder.actions';
import * as ProcedureActions from '../../../core/procedures/store/procedure.actions';

export const remindersFeatureKey = 'reminders';

export const adapter: EntityAdapter<ReminderRecord> = createEntityAdapter<
  ReminderRecord
>();

export const initialState: RemindersState = adapter.getInitialState({
  selectedReminderId: null,
  ids: []
});

export const reminderReducer = createReducer(
  initialState,
  on(ReminderActions.addReminder, (state, action) =>
    adapter.addOne(action.reminder, state)
  ),
  on(ReminderActions.upsertReminderSuccess, (state, action) =>
    adapter.upsertOne(
      { ...action.reminder, id: action.reminder.pk } as ReminderRecord,
      state
    )
  ),
  on(ReminderActions.addReminders, (state, action) =>
    adapter.addMany(action.reminders, state)
  ),
  on(ReminderActions.upsertReminders, (state, action) =>
    adapter.upsertMany(action.reminders, state)
  ),
  on(ReminderActions.updateReminder, (state, action) =>
    adapter.updateOne(action.reminder, state)
  ),
  on(ReminderActions.updateReminders, (state, action) =>
    adapter.updateMany(action.reminders, state)
  ),
  on(ReminderActions.deleteReminder, (state, action) =>
    adapter.removeOne(action.reminder.pk, state)
  ),
  on(ReminderActions.deleteReminders, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ReminderActions.loadRemindersSuccess, (state, action) =>
    adapter.setAll(
      action.reminders.map((item) => ({ ...item, id: item.pk })),
      state
    )
  ),
  on(ReminderActions.clearReminders, (state) => adapter.removeAll(state)),
  on(ProcedureActions.deleteProcedureSuccess, (state, action) =>
    adapter.removeOne(action?.reminder?.pk, state)
  )
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
