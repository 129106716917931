<mat-card class="example-card">
  <mat-card-content>
    <p>
      {{ data.message | translate }}
    </p>
  </mat-card-content>
</mat-card>
<mat-dialog-actions align="end">
  <button mat-button
    mat-dialog-close>{{'oniks.common.form.cancel' | translate}}</button>
  <button mat-button
    [mat-dialog-close]="true"
    cdkFocusInitial>{{'oniks.common.form.confirm' | translate}}</button>
</mat-dialog-actions>
