import { BackendService } from '../backend/backend.service';
/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as DynamoDB from 'aws-sdk/clients/dynamodb';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { DynamoDBRecordSet } from '../backend/backend.models';
import { Client } from '../../features/clients/clients.model';
import {
  ClientProceduresTable,
  ProcedureRecord
} from '../../shared/procedures/procedures.model';

@Injectable({
  providedIn: 'root'
})
export class ProceduresService {
  constructor(
    private http: HttpClient,
    private backendService: BackendService
  ) {}

  public add(record: ProcedureRecord): Observable<ProcedureRecord> {
    const body: DynamoDB.Types.PutItemInput = {
      TableName: `${ClientProceduresTable.name}-${environment.envName}`,
      Item: record as any as DynamoDB.PutItemInputAttributeMap
    };

    return this.http
      .post<void>(
        `${this.backendService.apiGwUrl}/${environment.endpoints.db}`,
        body,
        {
          headers: this.backendService.headers
        }
      )
      .pipe(map(() => record));
  }

  public update(procedure: ProcedureRecord): Observable<void> {
    const { pk, sk, ...fields } = procedure;
    const body: DynamoDB.Types.UpdateItemInput = {
      TableName: `${ClientProceduresTable.name}-${environment.envName}`,
      Key: {
        pk: procedure.pk as DynamoDB.Types.AttributeValue,
        sk: procedure.sk as DynamoDB.Types.AttributeValue
      },
      UpdateExpression:
        'set #zone = :zone, #code = :code, #iterationCount = :iterationCount, #nextReminder = :nextReminder, #price = :price, #regime = :regime, #intensity = :intensity, #frequency = :frequency, #updateDate = :updateDate, #comment = :comment',
      ExpressionAttributeNames: {
        '#zone':
          'zone' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#code':
          'code' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#iterationCount':
          'iterationCount' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#nextReminder':
          'nextReminder' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#price':
          'price' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#regime':
          'regime' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#intensity':
          'intensity' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#frequency':
          'frequency' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#updateDate':
          'updateDate' as any as DynamoDB.Types.ExpressionAttributeNameVariable,
        '#comment':
          'comment' as any as DynamoDB.Types.ExpressionAttributeNameVariable
      },
      ExpressionAttributeValues: {
        ':zone': procedure.zone as any as DynamoDB.Types.MapAttributeValue,
        ':code': procedure.code as any as DynamoDB.Types.MapAttributeValue,
        ':iterationCount':
          procedure.iterationCount as any as DynamoDB.Types.MapAttributeValue,
        ':nextReminder':
          procedure.nextReminder as any as DynamoDB.Types.MapAttributeValue,
        ':price': procedure.price as any as DynamoDB.Types.MapAttributeValue,
        ':regime': procedure.regime as any as DynamoDB.Types.MapAttributeValue,
        ':intensity':
          procedure.intensity as any as DynamoDB.Types.MapAttributeValue,
        ':frequency':
          procedure.frequency as any as DynamoDB.Types.MapAttributeValue,
        ':updateDate':
          procedure.updateDate as any as DynamoDB.Types.MapAttributeValue,
        ':comment': procedure.comment as any as DynamoDB.Types.MapAttributeValue
      }
    };

    return this.http.put<void>(
      `${this.backendService.apiGwUrl}/${environment.endpoints.db}`,
      body,
      {
        headers: this.backendService.headers
      }
    );
  }

  public delete(
    procedure: ProcedureRecord
  ): Observable<void | Record<string, never>> {
    return this.backendService.delete(
      `${ClientProceduresTable.name}-${environment.envName}`,
      procedure.pk as DynamoDB.Types.AttributeValue,
      { sk: procedure.sk as DynamoDB.Types.AttributeValue }
    );
  }

  public get(
    clientId: Client['id']
  ): Observable<DynamoDBRecordSet<ProcedureRecord>> {
    console.log('GET Client Procedures');
    return this.http.get<DynamoDBRecordSet<ProcedureRecord>>(
      `${this.backendService.apiGwUrl}/${environment.endpoints.clients}/${clientId}/procedures`,
      {
        headers: this.backendService.headers
      }
    );
  }
}
