import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { httpRequestStart, httpRequestEnd } from '../loading/loading.actions';

@Injectable()
export class LoadingHttpInterceptor implements HttpInterceptor {
  constructor(private store: Store) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Dispatch action to set loading state on request
    this.store.dispatch(httpRequestStart());

    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Dispatch action to reset loading state on response
            this.store.dispatch(httpRequestEnd());
          }
        },
        (error) => {
          // Dispatch action to reset loading state on error (optional)
          this.store.dispatch(httpRequestEnd());
        }
      )
    );
  }
}
