import { Action, createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as OrderActions from './order.actions';
import { Order, OrdersState } from '../orders.model';

export const ordersFeatureKey = 'orders';

export const adapter: EntityAdapter<Order> = createEntityAdapter<Order>();

export const initialState: OrdersState = adapter.getInitialState({
  selectedOrderId: null,
  ids: []
});

export const orderReducer = createReducer(
  initialState,
  on(OrderActions.addOrderSuccess, (state, action) =>
    adapter.addOne(action.order, state)
  ),
  on(OrderActions.upsertOrderSuccess, (state, action) =>
    adapter.upsertOne(action.order, state)
  ),
  on(OrderActions.addOrders, (state, action) =>
    adapter.addMany(action.orders, state)
  ),
  on(OrderActions.upsertOrders, (state, action) =>
    adapter.upsertMany(action.orders, state)
  ),
  on(OrderActions.updateOrder, (state, action) =>
    adapter.updateOne(action.order, state)
  ),
  on(OrderActions.updateOrders, (state, action) =>
    adapter.updateMany(action.orders, state)
  ),
  on(OrderActions.deleteOrderSuccess, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(OrderActions.deleteOrders, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(OrderActions.loadOrders, (state, action) =>
    adapter.setAll(action.orders, state)
  ),
  on(OrderActions.clearOrders, (state) => adapter.removeAll(state)),
  on(OrderActions.resetOrderForm, (state) => ({
    ...state,
    selectedOrderId: null
  })),
  on(OrderActions.setActiveOrder, (state, { selectedOrderId }) => ({
    ...state,
    selectedOrderId
  }))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
