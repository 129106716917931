import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ClientsState } from './clients.model';
import { adapter, clientsFeatureKey } from './clients.reducer';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectClients =
  createFeatureSelector<ClientsState>(clientsFeatureKey);
export const selectAllClients = createSelector(selectClients, selectAll);
export const selectClientEntities = createSelector(
  selectClients,
  selectEntities
);

export const getSelectedClient = createSelector(
  selectClients,
  selectClientEntities,
  (state, entities) =>
    state.selectedClientId ? entities[state.selectedClientId] : undefined
);

export const getActiveClient = createSelector(
  selectClients,
  selectClientEntities,
  (state: ClientsState, entities) => entities[state.selectedClientId]
);
