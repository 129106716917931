import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Client, ClientsState } from './clients.model';
import * as ClientsActions from './clients.actions';

export const clientsFeatureKey = 'clients';

export type State = EntityState<Client>;

export const adapter: EntityAdapter<Client> = createEntityAdapter<Client>();

export const initialState: ClientsState = adapter.getInitialState({
  selectedClientId: null,
  ids: []
});

export const clientReducer = createReducer(
  initialState,
  on(ClientsActions.addClient, (state, action) =>
    adapter.addOne(action.client, state)
  ),
  on(ClientsActions.upsertClient, (state, action) =>
    adapter.upsertOne(action.client, state)
  ),
  on(ClientsActions.addClients, (state, action) =>
    adapter.addMany(action.clients, state)
  ),
  on(ClientsActions.upsertClients, (state, action) =>
    adapter.upsertMany(action.clients, state)
  ),
  on(ClientsActions.updateClient, (state, action) =>
    adapter.updateOne(action.client, state)
  ),
  on(ClientsActions.updateClients, (state, action) =>
    adapter.updateMany(action.clients, state)
  ),
  on(ClientsActions.deleteClientSuccess, (state, action) =>
    adapter.removeOne(action.id, state)
  ),
  on(ClientsActions.deleteClients, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  on(ClientsActions.loadClients, (state, action) =>
    adapter.setAll(action.clients, state)
  ),
  on(ClientsActions.clearClients, (state) => adapter.removeAll(state)),
  on(ClientsActions.resetClientForm, (state) => ({
    ...state,
    selectedClientId: null
  })),
  on(ClientsActions.setActiveClient, (state, { selectedClientId }) => ({
    ...state,
    selectedClientId
  }))
);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal
} = adapter.getSelectors();
