import {
  getSelectedClient,
  selectAllClients
} from '../../clients/clients.selectors';
import { Order, OrderListItem } from '../orders.model';
import { Client } from '../../clients/clients.model';
import { adapter, ordersFeatureKey } from './order.reducer';
import { State, OrdersState } from '../orders.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '../../../core/core.state';

const { selectEntities, selectAll } = adapter.getSelectors();

export const selectOrders = createFeatureSelector<State, OrdersState>(
  ordersFeatureKey
);
export const selectAllOrders = createSelector(selectOrders, selectAll);
export const selectOrderEntities = createSelector(selectOrders, selectEntities);

export const getSelectedOrder = createSelector(
  selectOrderEntities,
  selectRouteParams,
  (entities, params) => params && entities[params.id]
);

export const getActiveOrder = createSelector(
  selectOrders,
  selectOrderEntities,
  (state: OrdersState, entities): Order => entities[state.selectedOrderId]
);

export const selectOrderItems = createSelector(
  selectAllOrders,
  selectAllClients,
  (orders: Order[], clients: Client[]) =>
    orders.map((order) => {
      const date = new Date(order.date);
      date.setHours(order.hour);
      date.setMinutes(order.minutes);
      return {
        ...order,
        id: order.id,
        clientName:
          clients.find((client) => client.id === order.clientId)?.name || '',
        date,
        price: 2,
        procedures: order.procedures
      } as OrderListItem;
    })
);

export const selectClientOrders = createSelector(
  selectAllOrders,
  getSelectedClient,
  (orders: Order[], client: Client) =>
    orders
      .filter((order) => client && order.clientId === client.id)
      .map((order) => {
        const date = new Date(order.date);
        date.setHours(order.hour);
        date.setMinutes(order.minutes);
        return {
          ...order,
          id: order.id,
          clientName: client?.name || '',
          date,
          price: 2,
          procedures: order.procedures
        } as OrderListItem;
      })
);
