import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as ProcedureActions from './procedure.actions';
import * as ClientsActions from '../../../features/clients/clients.actions';
import * as OrderActions from '../../../features/orders/store/order.actions';
import {
  ProcedureRecord,
  ProceduresState
} from '../../../shared/procedures/procedures.model';

export const proceduresFeatureKey = 'procedures';

export const adapter: EntityAdapter<ProcedureRecord> =
  createEntityAdapter<ProcedureRecord>();

export const initialState: ProceduresState = adapter.getInitialState({
  selectedProcedureId: null,
  selectedOrderId: null,
  selectedClientId: null,
  lastUpdate: null,
  ids: []
});

export const proceduresReducer = createReducer(
  initialState,
  on(ProcedureActions.addProcedureSuccess, (state, action) =>
    adapter.addOne(action.procedure, state)
  ),
  on(ProcedureActions.upsertProcedureSuccess, (state, action) =>
    adapter.upsertOne(action.procedure, state)
  ),
  on(ProcedureActions.addProcedures, (state, action) =>
    adapter.addMany(action.procedures, state)
  ),
  on(ProcedureActions.upsertProcedures, (state, action) =>
    adapter.upsertMany(action.procedures, state)
  ),
  on(ProcedureActions.updateProcedure, (state, action) =>
    adapter.updateOne(action.procedure, state)
  ),
  on(ProcedureActions.updateProcedures, (state, action) =>
    adapter.updateMany(action.procedures, state)
  ),
  on(ProcedureActions.deleteProcedure, (state, action) =>
    adapter.removeOne(action.procedure.id, state)
  ),
  on(ProcedureActions.deleteProcedures, (state, action) =>
    adapter.removeMany(action.ids, state)
  ),
  // on(ProcedureActions.loadProcedures, (state, action) => ({
  //   ...state,
  //   lastUpdate: { ...state.lastUpdate, [state.selectedClientId]: Date.now() }
  // })),
  on(ProcedureActions.loadProceduresSuccess, (state, action) =>
    adapter.setAll(
      action.procedures.map((procedure) => ({
        ...procedure,
        id: procedure.sk.split('#')[2]
      })),
      state
    )
  ),
  on(ProcedureActions.clearProcedures, (state) => adapter.removeAll(state)),
  on(ClientsActions.setActiveClient, (state, { selectedClientId }) => ({
    ...state,
    selectedClientId,
    selectedOrderId: null
  })),
  on(OrderActions.setActiveOrder, (state, { selectedOrderId }) => ({
    ...state,
    selectedOrderId
  })),
  on(ProcedureActions.setActiveProcedure, (state, { selectedProcedureId }) => ({
    ...state,
    selectedProcedureId
  }))
);

export const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();
