import {
  dynamicFrequencies,
  dynamicIntensities,
  staticFrequencies,
  staticIntensities
} from './procedure.nomenclatures';
import { ProcedureRecord, ClientProceduresTable } from '../procedures.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { combineLatest, Observable, of, Subject, zip } from 'rxjs';
import { Procedure, State } from '../procedures.model';
import {
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ROUTE_ANIMATIONS_ELEMENTS } from '../../../core/core.module';
import {
  getActiveProcedure,
  getClientProcedures,
  getOrderClientProcedures,
  getProcedureItems,
  getProcedureIterationCnt,
  getProcedureRegime,
  getProcedureReminderPeriods,
  getProcedureZones
} from '../../../core/procedures/store/procedure.selectors';
import {
  upsertProcedure,
  setActiveProcedure
} from '../../../core/procedures/store/procedure.actions';
import { getActiveClient } from '../../../features/clients/clients.selectors';
import { getActiveOrder } from '../../../features/orders/store/order.selectors';

@Component({
  selector: 'oniks-procedure',
  templateUrl: './procedure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureComponent implements OnInit, OnDestroy {
  procedures$: Observable<Procedure[]>;

  iterationCounts$: Observable<number[]>;

  zones$;

  regimes$: Observable<Procedure['regime'][]>;

  nextReminders$: Observable<Procedure['nextReminder'][]>;

  activeProcedure$: Observable<ProcedureRecord>;

  intensities: number[];

  frequencies: number[];

  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;

  form: UntypedFormGroup = this.fb.group({
    id: [''],
    pk: [''],
    sk: [''],
    createDate: [],
    zone: ['', [Validators.required]],
    code: ['', [Validators.required]],
    iterationCount: ['', [Validators.required]],
    price: ['', [Validators.pattern('^[0-9]*$')]],
    nextReminder: [30, [Validators.required]],
    regime: ['dynamic', [Validators.required]],
    intensity: [''],
    frequency: [''],
    comment: ['']
  });

  private unsubscribe$ = new Subject<void>();

  constructor(public store: Store<State>, public fb: UntypedFormBuilder) {
    this.zones$ = this.store.pipe(select(getProcedureZones));
    this.regimes$ = this.store.pipe(select(getProcedureRegime));
    this.nextReminders$ = this.store.pipe(select(getProcedureReminderPeriods));
    this.activeProcedure$ = this.store.pipe(select(getActiveProcedure));
  }

  ngOnInit(): void {
    // this.toggleDisabled(false);
    this.watchRegime();
    this.watchZones();
    this.watchCode();
    this.store
      .pipe(
        takeUntil(this.unsubscribe$),
        select(getActiveProcedure),
        filter((c) => !!c),
        distinctUntilChanged((a, b) => a?.id === b?.id),
        tap((form) => this.form.patchValue(form))
      )
      .subscribe();

    this.store
      .pipe(
        select(getOrderClientProcedures),
        filter((el) => !!el),
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => a[0]?.id === b[0]?.id)
      )
      .subscribe();
  }

  submit(): false {
    if (this.form.valid) {
      const procedureRecord: ProcedureRecord = this.form.value;
      const activeOrder$ = this.store.pipe(select(getActiveOrder));
      const activeClient$ = this.store.pipe(select(getActiveClient));

      zip(this.activeProcedure$, activeClient$, activeOrder$)
        .pipe(
          first(),
          tap(([activeProcedure, client, order]) => {
            let clientProcedure: ProcedureRecord;
            const action = upsertProcedure;
            const now = new Date().getTime();

            if (!activeProcedure) {
              const id: string = uuid();
              clientProcedure = {
                ...procedureRecord,
                id,
                pk: ClientProceduresTable.partitionKeyPrefix + client.id,
                sk:
                  ClientProceduresTable.orderKeyPrefix +
                  order.id +
                  ClientProceduresTable.procedureKeyPrefix +
                  id,
                createDate: now,
                updateDate: now
              };
            } else {
              clientProcedure = {
                ...procedureRecord,
                updateDate: now
              };
            }
            this.store.dispatch(
              action({ procedure: clientProcedure, client, order })
            );
            this.form.reset();
            this.store.dispatch(
              setActiveProcedure({ selectedProcedureId: null })
            );
          })
        )
        .subscribe();
    }
    return false;
  }

  cancel(): void {
    this.store.dispatch(setActiveProcedure({ selectedProcedureId: null }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(setActiveProcedure({ selectedProcedureId: null }));
  }

  private toggleDisabled(enable: boolean): void {
    Object.entries(this.form.controls)
      .filter(([k]) => k !== 'zone')
      .forEach(([k, control]) => {
        if (enable) {
          control.enable();
        } else {
          control.disable();
        }
      });
  }

  private watchZones(): void {
    this.procedures$ = this.form.controls['zone'].valueChanges.pipe(
      startWith('all'),
      withLatestFrom(this.store.pipe(select(getProcedureItems))),
      // tap(([zone]) => this.toggleDisabled(zone !== 'all')),
      map(([zone, items]) =>
        zone === 'all' ? items : items.filter((item) => item.zone === zone)
      )
    );
  }

  private watchRegime(): void {
    this.form
      .get('regime')
      .valueChanges.pipe(
        startWith('dynamic'),
        takeUntil(this.unsubscribe$),
        tap((regime) => {
          if (regime === 'static') {
            this.intensities = staticIntensities;
            this.frequencies = staticFrequencies;
          } else {
            this.intensities = dynamicIntensities;
            this.frequencies = dynamicFrequencies;
          }
        })
      )
      .subscribe();
  }

  private watchCode(): void {
    this.iterationCounts$ = this.form.get('code').valueChanges.pipe(
      startWith(this.form.get('code').value),
      switchMap((code) =>
        zip(
          of(code),
          combineLatest([
            this.store.pipe(select(getProcedureIterationCnt)),
            this.store.pipe(select(getClientProcedures)),
            this.store.pipe(select(getActiveProcedure))
          ])
        )
      ),
      map(([code, [iterationCountAll, clientProcedures, activeProcedure]]) =>
        iterationCountAll.filter(
          (iterationCount) =>
            !clientProcedures.find((el) => {
              const notAnActiveProcedure = el.id !== activeProcedure?.id;
              return (
                notAnActiveProcedure &&
                el.iterationCount === iterationCount &&
                el.code === (code || activeProcedure?.code)
              );
            })
        )
      )
    );
  }
}
