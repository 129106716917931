import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Reminder, ReminderRecord } from '../reminders.model';

export const loadReminders = createAction('[Reminder/API] Load Reminders');

export const loadRemindersSuccess = createAction(
  '[Reminder/API] Load Reminders Success',
  props<{ reminders: Reminder[] }>()
);

export const addReminder = createAction(
  '[Reminder/API] Add Reminder',
  props<{ reminder: Reminder }>()
);

export const upsertReminderSuccess = createAction(
  '[Reminder/API] Upsert Reminder success',
  props<{ reminder: Reminder }>()
);

export const addReminders = createAction(
  '[Reminder/API] Add Reminders',
  props<{ reminders: Reminder[] }>()
);

export const upsertReminders = createAction(
  '[Reminder/API] Upsert Reminders',
  props<{ reminders: Reminder[] }>()
);

export const updateReminder = createAction(
  '[Reminder/API] Update Reminder',
  props<{ reminder: Update<Reminder> }>()
);

export const updateReminders = createAction(
  '[Reminder/API] Update Reminders',
  props<{ reminders: Update<Reminder>[] }>()
);

export const noReminderToDelete = createAction(
  '[Reminder/API] No Reminder to Delete'
);

export const deleteReminder = createAction(
  '[Reminder/API] Delete Reminder',
  props<{ reminder: ReminderRecord }>()
);

export const deleteReminders = createAction(
  '[Reminder/API] Delete Reminders',
  props<{ ids: string[] }>()
);

export const clearReminders = createAction('[Reminder/API] Clear Reminders');
