/* eslint-disable @typescript-eslint/naming-convention */
import { Order } from '../orders/orders.model';
import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core/core.state';
import { DynamoDbOneToManyPattern } from '../../core/backend/backend.models';

export enum ClientsRoutes {
  'form' = '/clients/client',
  'list' = '/clients/client-list'
}

export type Gender = 'm' | 'f';
export type Phototype = 'I' | 'II' | 'III' | 'IV' | 'V' | 'VI';
export interface Client {
  id: string;
  name: string;
  email: string;
  phone: string;
  cardNumber: string;
  gender: Gender;
  phototype: Phototype;
  birthDate?: Date;
}

export interface ClientRecord extends DynamoDbOneToManyPattern {
  client: Client;
}
export interface OrderRecord extends DynamoDbOneToManyPattern {
  order: Order;
  orderDate: number;
}

export type ClientOrder = ClientRecord | OrderRecord;

export interface ClientsState extends EntityState<Client> {
  selectedClientId: string;
}

export interface State extends AppState {
  clients: ClientsState;
}
