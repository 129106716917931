<div class="py-2"
  fxlayout="row"
  fxLayoutAlign="space-between">
  <div class="coll">
    <h1 class="main-heading">{{ 'oniks.procedures.title' | translate }}</h1>
  </div>

  <a *ngIf="canAdd"
    (click)="openCreateForm()">
    <button class="col"
      mat-fab
      color="primary"
      aria-label="Add procedure">
      <fa-icon icon="plus-circle"></fa-icon>
    </button>
  </a>
</div>

<div *ngIf="dataSource?.data"
  class="mat-elevation-z8 row">
  <mat-card class="col">
    <mat-form-field>
      <mat-label>{{'oniks.common.table.filter' | translate}}</mat-label>
      <input matInput
        (keyup)="applyFilter($event)"
        placeholder="{{'oniks.common.table.filter-ex' | translate}}"
        #input>
    </mat-form-field>

    <div class="row">
      <table mat-table
        class="col"
        [dataSource]="dataSource"
        matSort>

        <!-- Procedure -->
        <ng-container matColumnDef="code"
          sticky>
          <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'oniks.procedures.form.procedure' | translate}} </th>
          <td mat-cell
            *matCellDef="let row">

            <button mat-flat-button
              class="p-0"
              (click)="edit(row.id)">
              {{row.code}}
            </button>

          </td>
        </ng-container>

        <!-- Iteration -->
        <ng-container matColumnDef="iterationCount"
          sticky>
          <th mat-header-cell
            class="text-center"
            *matHeaderCellDef
            mat-sort-header> {{'oniks.procedures.form.iterationCount' | translate}} </th>
          <td mat-cell
            class="text-center p-0"
            *matCellDef="let row"> {{row.iterationCount}} </td>
        </ng-container>

        <!-- Frequency -->
        <ng-container matColumnDef="frequency"
          sticky>
          <th mat-header-cell
            fxHide
            fxShow.gt-sm
            class="text-end"
            *matHeaderCellDef
            mat-sort-header> {{'oniks.procedures.form.frequency' | translate}} </th>
          <td mat-cell
            fxHide
            fxShow.gt-sm
            class="text-end"
            *matCellDef="let row"> {{row.frequency}} Hz </td>
        </ng-container>

        <!-- Intensity -->
        <ng-container matColumnDef="intensity"
          sticky>
          <th mat-header-cell
            fxHide
            fxShow.gt-sm
            class="text-end"
            *matHeaderCellDef
            mat-sort-header> {{'oniks.procedures.form.intensity' | translate}} </th>
          <td mat-cell
            fxHide
            fxShow.gt-sm
            class="text-end"
            *matCellDef="let row"> {{row.intensity}} J </td>
        </ng-container>

        <!-- Price -->
        <ng-container matColumnDef="price">
          <th mat-header-cell
            fxHide
            fxShow.gt-xs
            class="text-end"
            *matHeaderCellDef
            mat-sort-header> {{'oniks.procedures.form.price' | translate}} </th>
          <td mat-cell
            fxHide
            fxShow.gt-xs
            class="text-end"
            *matCellDef="let row"> {{row.price}} </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="orderDate">
          <th mat-header-cell
            class="text-end"
            *matHeaderCellDef
            mat-sort-header>
            {{'oniks.orders.table.date' | translate}}
          </th>
          <td mat-cell
            class="text-end"
            *matCellDef="let row">

            <button mat-flat-button
              (click)="navigateToOrder(row.orderId)">
              {{row.orderDate | uppercase}}
            </button>
          </td>
        </ng-container>

        <!-- Actions column -->
        <div *ngIf="!!canAdd">
          <ng-container matColumnDef="id"
            stickyEnd>
            <th mat-header-cell
              class="justify-content-end"
              fxShow.gt-sm
              *matHeaderCellDef
              mat-sort-header>
            </th>
            <td mat-cell
              *matCellDef="let row">
              <mat-icon class="col-6 align-self-start">
                <fa-icon icon="edit"
                  fxHide
                  fxShow.gt-sm
                  (click)="edit(row.id)"
                  [matTooltip]="'oniks.common.table.tooltip.edit' | translate"
                  matTooltipPosition="above">
                </fa-icon>
              </mat-icon>
              <mat-icon class="col-6 align-self-end">
                <fa-icon icon="trash"
                  (click)="delete(row.id)"
                  [matTooltip]="'oniks.common.table.tooltip.delete' | translate"
                  matTooltipPosition="above">
                </fa-icon>
              </mat-icon>

          </ng-container>
        </div>

        <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
          *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row"
          *matNoDataRow>
          <td class="mat-cell"
            colspan="4">{{'oniks.common.table.no-data-matching-filter' | translate}}
            "{{input.value}}"
          </td>
        </tr>

      </table>
    </div>
    <div class="row">
      <mat-paginator [pageSizeOptions]="[10, 25, 100]"
        class="col"></mat-paginator>
    </div>
  </mat-card>
</div>
