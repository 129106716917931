import { ReminderRecord } from '../../../features/reminders/reminders.model';
import { Client } from '../../../features/clients/clients.model';
import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Order } from '../../../features/orders/orders.model';
import { ProcedureRecord } from '../../../shared/procedures/procedures.model';

export const loadProcedures = createAction('[Procedure/API] Load Procedures');

export const loadProceduresSuccess = createAction(
  '[Procedure/API] Load Procedures Success',
  props<{ procedures: ProcedureRecord[] }>()
);

export const addProcedure = createAction(
  '[Procedure/API] Add Procedure',
  props<{ procedure: ProcedureRecord; order: Order; client: Client }>()
);

export const addProcedureSuccess = createAction(
  '[Procedure/API] Add Procedure success',
  props<{ procedure: ProcedureRecord; order: Order }>()
);

export const upsertProcedure = createAction(
  '[Procedure/API] Upsert Procedure',
  props<{ procedure: ProcedureRecord; order: Order; client: Client }>()
);

export const upsertProcedureSuccess = createAction(
  '[Procedure/API] Upsert Procedure success',
  props<{
    procedure: ProcedureRecord;
    order: Order;
    triggerOrderUpsert: boolean;
  }>()
);

export const addProcedures = createAction(
  '[Procedure/API] Add Procedures',
  props<{ procedures: ProcedureRecord[] }>()
);

export const upsertProcedures = createAction(
  '[Procedure/API] Upsert Procedures',
  props<{ procedures: ProcedureRecord[] }>()
);

export const updateProcedure = createAction(
  '[Procedure/API] Update Procedure',
  props<{ procedure: Update<ProcedureRecord> }>()
);

export const updateProcedures = createAction(
  '[Procedure/API] Update Procedures',
  props<{ procedures: Update<ProcedureRecord>[] }>()
);

export const deleteProcedure = createAction(
  '[Procedure/API] Delete Procedure',
  props<{
    procedure: ProcedureRecord;
    order?: Order;
    triggerOrderUpsert?: boolean;
  }>()
);

export const deleteOrderProcedures = createAction(
  '[Procedure/API] Delete order Procedures',
  props<{ procedures: ProcedureRecord[]; order: Order }>()
);

export const deleteProcedureSuccess = createAction(
  '[Procedure/API] Delete Procedure success',
  props<{
    reminder: ReminderRecord;
    order: Order;
    triggerOrderUpsert: boolean;
  }>()
);

export const deleteProcedureFail = createAction(
  '[Procedure/API] Delete Procedure fail'
);

export const deleteProcedures = createAction(
  '[Procedure/API] Delete Procedures',
  props<{ ids: string[] }>()
);

export const clearProcedures = createAction('[Procedure/API] Clear Procedures');
export const setActiveProcedure = createAction(
  '[Procedure/API] Set active',
  props<{ selectedProcedureId: ProcedureRecord['id'] }>()
);
