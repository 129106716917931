import { AppState } from '../../../core/core.state';
import {
  deleteProcedure,
  setActiveProcedure
} from '../../../core/procedures/store/procedure.actions';
import {
  distinctUntilChanged,
  filter,
  map,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Order, OrdersRoutes } from '../../../features/orders/orders.model';
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Input
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { Observable, Subject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Output, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ClientsRoutes } from '../../../features/clients/clients.model';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { NotificationService } from '../../../core/core.module';
import { getActiveOrder } from '../../../features/orders/store/order.selectors';
import { OrderProcedureRecord } from '../procedures.model';
import { Router } from '@angular/router';

@Component({
  selector: 'oniks-procedure-list',
  templateUrl: './procedure-list.component.html',
  styleUrls: ['procedure-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DatePipe]
})
export class ProcedureListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() procedureList$: Observable<OrderProcedureRecord[]>;
  @Input() canAdd = false;

  @Output() deleteProcedureEvent = new EventEmitter<string>();

  dataSource = new MatTableDataSource<OrderProcedureRecord>([]);

  displayedColumns: (keyof OrderProcedureRecord)[] = [
    'code',
    'iterationCount',
    'frequency',
    'intensity',
    'price',
    'orderDate'
  ];

  clientsRoute = ClientsRoutes.form;

  addOrderRoute = OrdersRoutes.form;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.canAdd) this.displayedColumns.push('id');
    this.procedureList$
      .pipe(
        distinctUntilChanged(
          (a, b) => JSON.stringify(a || {}) === JSON.stringify(b || {})
        ),
        map((el) =>
          el.map((item) => ({
            ...item,
            code: this.translateService.instant(
              `oniks.procedures.form.${item.code}`
            ),
            regime: this.translateService.instant(
              `oniks.procedures.form.regimes-short.${item.regime}`
            ),
            orderDate: this.datePipe.transform(
              new Date(item.date),
              'dd.MMM.yyyy'
            )
          }))
        )
      )
      .subscribe((data) => (this.dataSource.data = data));
  }

  openCreateForm(): void {
    this.store.dispatch(setActiveProcedure({ selectedProcedureId: 'new' }));
  }

  navigateToOrder(orderId: Order['id']): void {
    this.router.navigate([OrdersRoutes.form, orderId]);
  }

  edit(selectedProcedureId: string): void {
    this.store.dispatch(setActiveProcedure({ selectedProcedureId }));
  }

  delete(id: string): void {
    const itemToDelete = this.dataSource.data.find((item) => item.id === id);
    this.notificationService
      .confirm(
        this.translateService.instant(
          'oniks.common.table.actions.confirm-delete',
          {
            record: itemToDelete.code
          }
        )
      )
      .pipe(
        filter((result) => result),
        withLatestFrom(this.store.pipe(select(getActiveOrder))),
        tap(([p, order]) => {
          this.store.dispatch(
            deleteProcedure({
              procedure: itemToDelete,
              order,
              triggerOrderUpsert: true
            })
          );
          this.store.dispatch(
            setActiveProcedure({ selectedProcedureId: null })
          );
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        default:
          return item[property];
      }
    };

    setTimeout(() =>
      this.sort.sort({
        id: 'date',
        start: 'desc'
      } as MatSortable)
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
