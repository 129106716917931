import { Client } from '../clients/clients.model';
import { EntityState } from '@ngrx/entity';
import { AppState } from '../../core/core.state';
import { Procedure } from '../../shared/procedures/procedures.model';

export enum OrdersRoutes {
  'form' = '/orders/order',
  'orderForClient' = '/orders/client',
  'list' = '/orders/order-list'
}

export type Zone = 'face' | 'body' | 'legs';

export type ProcedureCode =
  | 'lower-legs'
  | 'hands'
  | 'back'
  | 'neck'
  | 'breasts'
  | 'nape'
  | 'cheekbones'
  | 'beard'
  | 'sideburns'
  | 'throat'
  | 'neck'
  | 'armpits'
  | 'stomach-line'
  | 'bikini'
  | 'shoulders'
  | 'half-hands'
  | 'stomach'
  | 'waist'
  | 'breasts'
  | 'backside'
  | 'thighs'
  | 'armpits'
  | 'top-lips'
  | 'beard'
  | 'intimate';

export interface Order {
  id: string;
  clientId: Client['id'];
  date: Date;
  hour: number;
  minutes: number;
  procedures: Procedure[];
  price: number;
  comment: string;
  eventId: string;
}

export interface OrderListItem extends Order {
  clientName: string;
}

export interface OrdersState extends EntityState<Order> {
  selectedOrderId: Order['id'];
}
export interface State extends AppState {
  orders: OrdersState;
}
