<div class="py-2"
  fxlayout="row"
  fxLayoutAlign="space-between">
  <div class="col">
    <h1 class="main-heading">
      {{ (!(activeProcedure$ | async) ? 'oniks.procedures.form.procedure-add' : 'oniks.procedures.form.procedure-edit') | translate }}
    </h1>
  </div>

  <a (click)="cancel()">
    <button class="col"
      mat-fab
      color="primary"
      aria-label="Show procedure list">
      <fa-icon icon="list"></fa-icon>
    </button>
  </a>
</div>
<mat-card class="row mat-elevation-z8">
  <mat-card-header></mat-card-header>
  <mat-card-content>
    <form [formGroup]="form"
      (ngSubmit)="submit()"
      [ngClass]="routeAnimationsElements">

      <div class="row">
        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.zone' | translate}}</mat-label>
          <mat-select formControlName="zone"
            required
            name="zone">
            <mat-option *ngFor="let zone of zones$ | async"
              [value]="zone">
              {{ 'oniks.procedures.form.zones.'+zone | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('zone').invalid">
            {{ 'oniks.procedures.form.zone' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.procedure' | translate}}</mat-label>
          <mat-select formControlName="code"
            [value]="(activeProcedure$ | async)?.code"
            required
            name="code">
            <mat-option *ngFor="let procedure of procedures$ | async"
              [value]="procedure.code">
              {{ 'oniks.procedures.form.'+procedure.code | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('code').invalid">
            {{ 'oniks.procedures.form.procedure' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.iterationCount' | translate}}</mat-label>
          <mat-select formControlName="iterationCount"
            required
            name="iterationCount">
            <mat-option *ngFor="let iterationCount of iterationCounts$ | async"
              [value]="iterationCount">
              {{ iterationCount }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('iterationCount').invalid">
            {{ 'oniks.procedures.form.iterationCount' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.regime' | translate}}</mat-label>
          <mat-select formControlName="regime"
            required
            autofocus
            name="frequency">
            <mat-option *ngFor="let regime of regimes$ | async"
              [value]="regime">
              {{ 'oniks.procedures.form.regimes.'+regime | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('regime').invalid">
            {{ 'oniks.procedures.form.regime' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.frequency' | translate}} (Hz)</mat-label>
          <mat-select formControlName="frequency"
            name="frequency">
            <mat-option *ngFor="let frequency of frequencies"
              [value]="frequency">
              {{ frequency }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('frequency').invalid">
            {{ 'oniks.procedures.form.frequency' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.intensity' | translate}} (J)</mat-label>
          <mat-select formControlName="intensity"
            name="code">
            <mat-option *ngFor="let intensity of intensities"
              [value]="intensity">
              {{ intensity }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('intensity').invalid">
            {{ 'oniks.procedures.form.intensity' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="row">

        <mat-form-field class="col-md-4">
          <mat-label>{{'oniks.procedures.form.nextReminder' | translate}}</mat-label>
          <mat-select formControlName="nextReminder"
            name="nextReminder">
            <mat-option *ngFor="let nextReminder of nextReminders$ | async"
              [value]="nextReminder">
              {{ nextReminder>0 ? nextReminder : 'oniks.procedures.form.noNextReminder' | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('nextReminder').invalid">
            {{ 'oniks.procedures.form.nextReminder' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="col-md-4">
          <input matInput
            placeholder="{{ 'oniks.procedures.form.price' | translate }}"
            formControlName="price">
          <mat-error *ngIf="form.get('price').invalid">
            {{ 'oniks.procedures.form.price' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>

      </div>

      <div class="row">

        <mat-form-field class="col-md-12">
          <textarea matInput
            placeholder="{{ 'oniks.procedures.form.comment' | translate }}"
            formControlName="comment">{{ form }}</textarea>
          <mat-error *ngIf="form.get('comment').invalid">
            {{ 'oniks.procedures.form.comment' | translate }}
            {{ 'oniks.common.form.invalid' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row"
        fxLayoutAlign="end center">

        <div class="px-2">
          <button (click)="cancel()"
            mat-raised-button
            color="primary">
            {{ 'oniks.common.form.cancel' | translate }}
          </button>
        </div>

        <div *ngIf="activeProcedure$"
          class="d-flex justify-content-end">
          <button (click)="submit()"
            [disabled]="!form.valid"
            mat-raised-button
            color="primary">
            {{ ((!!(activeProcedure$ | async)) ? 'oniks.common.form.save' : 'oniks.common.form.add') | translate }}
          </button>
        </div>
      </div>

    </form>
  </mat-card-content>
  <mat-card-footer></mat-card-footer>
</mat-card>
