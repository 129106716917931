import { Environment } from './environment.model';
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../../../package.json');

export const environment: Environment = {
  appName: 'ONIKS',
  envName: 'prod',
  apiBaseUrl: 'https://2uonpw4dm7.execute-api.eu-central-1.amazonaws.com',
  applicationBaseUrl: 'https://oniks.click',
  apiKey: 'ase0RZsCbp11dpFP0EpD64Gxtw22JTlj1peH84dr',
  apiVersion: 'v1',
  production: true,
  dev: false,
  i18nPrefix: '',
  versions: {
    app: packageJson.version,
    angular: packageJson.dependencies['@angular/core'],
    ngrx: packageJson.dependencies['@ngrx/store'],
    material: packageJson.dependencies['@angular/material'],
    bootstrap: packageJson.dependencies.bootstrap,
    rxjs: packageJson.dependencies.rxjs,
    ngxtranslate: packageJson.dependencies['@ngx-translate/core'],
    fontAwesome: packageJson.dependencies['@fortawesome/fontawesome-free'],
    angularCli: packageJson.devDependencies['@angular/cli'],
    typescript: packageJson.devDependencies['typescript'],
    cypress: packageJson.devDependencies['cypress'],
    eslint: packageJson.devDependencies['eslint']
  },
  endpoints: {
    db: 'db',
    clientProcedures: 'client-procedures',
    calendar: 'calendar',
    clients: 'clients',
    reminders: 'reminders'
  }
};
