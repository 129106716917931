/* eslint-disable @typescript-eslint/naming-convention */
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DynamoDBRecordSet } from './backend.models';
import { DynamoDB } from 'aws-sdk';
import { tap } from 'rxjs/operators';
import { Client } from '../../features/clients/clients.model';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  constructor(private http: HttpClient) {}

  public get apiGwUrl(): string {
    return `${environment.apiBaseUrl}/${environment.envName}/${environment.apiVersion}`;
  }

  public get headers(): HttpHeaders {
    const headers = new HttpHeaders({ 'X-API-KEY': environment.apiKey });
    return headers;
  }

  public scan<T>(tableName: string): Observable<DynamoDBRecordSet<T>> {
    return this.http.get<DynamoDBRecordSet<T>>(
      `${this.apiGwUrl}/${environment.endpoints.db}`,
      {
        params: { TableName: tableName },
        headers: this.headers
      }
    );
  }

  public delete(
    tableName: string,
    pk: DynamoDB.Types.AttributeValue,
    sk: { [key: string]: DynamoDB.Types.AttributeValue }
  ): Observable<void | Record<string, never>> {
    const [skKey, skValue] = Object.entries(sk)[0];
    const body: DynamoDB.Types.DeleteItemInput = {
      TableName: tableName,
      Key: {
        pk,
        [skKey]: skValue
      }
    };
    const options = {
      headers: this.headers,
      body
    };

    return this.http
      .delete<void>(`${this.apiGwUrl}/${environment.endpoints.db}`, options)
      .pipe(tap((deleteRes) => console.log({ deleteRes })));
  }
}
