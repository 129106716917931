import { NotificationService } from './notification.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ConfirmComponent } from './confirm/confirm.component';
@NgModule({
  declarations: [ConfirmComponent],
  imports: [CommonModule, SharedModule],
  providers: [NotificationService],
  exports: []
})
export class NotificationModule {}
