import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './../notifications/notification.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ofType, createEffect, Actions } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { LocalStorageService } from '../local-storage/local-storage.service';

import {
  authLoginAttempt,
  authLogout,
  authLoginSuccess,
  authLoginFailure
} from './auth.actions';
import { OrdersRoutes } from '../../features/orders/orders.model';

export const AUTH_KEY = 'AUTH';

@Injectable()
export class AuthEffects {
  login = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginAttempt),
        map(({ userName, password }) =>
          userName === 'admin' && password === 'Ori6te'
            ? authLoginSuccess()
            : authLoginFailure()
        )
      ),
    { dispatch: true }
  );

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginSuccess),
        tap(() =>
          this.localStorageService.setItem(AUTH_KEY, { isAuthenticated: true })
        ),
        tap(() => this.router.navigate([OrdersRoutes.list]))
      ),
    { dispatch: false }
  );

  loginFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLoginFailure),
        tap(() =>
          this.notificationService.error('oniks.login.invalidCredentials')
        )
      ),
    { dispatch: false }
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.router.navigate(['login']);
          this.localStorageService.setItem(AUTH_KEY, {
            isAuthenticated: false
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private router: Router
  ) {}
}
