<!-- <div class="app-loading">
  <div class="logo">
    <svg class="spinner"
      viewBox="25 25 50 50">
      <circle class="path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10" />
    </svg>
  </div>
</div> -->
<div [class]="'theme-wrapper ' + (theme$ | async)">

  <mat-sidenav-container>

    <mat-sidenav #sidenav
      mode="push">
      <div class="branding"><img [src]="logo"
          [alt]="logo" />
        <span>{{ 'oniks.title.short' | translate}}</span>
      </div>
      <mat-nav-list>
        <a mat-list-item
          *ngFor="let item of navigationSideMenu"
          (click)="sidenav.close()"
          [routerLink]="[item.link]"
          routerLinkActive="active">
          {{item.label | translate}}
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <div class="wrapper">

      <div class="toolbar"
        [style.position]="(stickyHeader$ | async) ? 'fixed' : 'inherit'"
        [class.mdc-elevation-z4]="(stickyHeader$ | async)">
        <mat-toolbar color="primary">
          <button mat-icon-button
            class="d-md-none"
            (click)="sidenav.open()">
            <fa-icon icon="bars"></fa-icon>

          </button>

          <span routerLink=""
            class="branding spacer center d-inline d-sm-none">
            <img [src]="logo"
              [alt]="logo" /></span>
          <span routerLink=""
            class="branding spacer center d-none d-sm-inline d-md-none"><img [src]="logo"
              [alt]="logo" /> {{
            'oniks.title.short' | translate }}</span>
          <span routerLink=""
            class="branding spacer d-none d-md-inline"><img [src]="logo"
              [alt]="logo" /> {{ 'oniks.title.long' | translate
            }}</span>

          <span *ngIf="(isAuthenticated$ | async)"
            class="d-none d-md-inline">
            <button mat-button
              class="nav-button"
              *ngFor="let item of navigation"
              [routerLink]="[item.link]"
              routerLinkActive="active">
              {{item.label | translate}}
            </button>
          </span>

          <button *ngIf="(isAuthenticated$ | async)"
            mat-icon-button
            [matMenuTriggerFor]="toolbarUserMenu">
            <fa-icon icon="user-circle"></fa-icon>
          </button>
          <mat-menu #toolbarUserMenu="matMenu">
            <button mat-menu-item
              (click)="onLogoutClick()">
              <mat-icon>
                <fa-icon icon="power-off"></fa-icon>
              </mat-icon>
              <span>{{ 'oniks.menu.logout' | translate }}</span>
            </button>
          </mat-menu>

          <button mat-icon-button
            routerLink="settings"
            class="d-none d-sm-inline">
            <fa-icon icon="cog"></fa-icon>
          </button>

          <span *ngIf="language$ | async as language">
            <mat-select [ngModel]="language"
              (selectionChange)="onLanguageSelect($event)">
              <mat-option *ngFor="let l of languages"
                [value]="l">
                {{ l.toUpperCase() }}
              </mat-option>
            </mat-select>
          </span>
        </mat-toolbar>
      </div>

      <div class="content"
        fxLayout="column"
        [@routeAnimations]="o.isActivated && o.activatedRoute.routeConfig.data && o.activatedRoute.routeConfig.data.title">
        <router-outlet #o="outlet"></router-outlet>
      </div>

      <div class="footer">
        <div class="row">
          <div class="col-12 signature">
            &#169; <span class="year">{{year}}</span> - Iliya Chepishev
            <br class="d-block d-sm-none">
          </div>
        </div>
      </div>

    </div>

  </mat-sidenav-container>

</div>

<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
