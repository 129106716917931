import { selectAllReminderRecords } from '../../../features/reminders/store/reminder.selectors';
import { ProceduresService } from '../procedures.service';
import { selectShouldUpdatedClientProcedures } from './procedure.selectors';
import {
  addProcedure,
  addProcedureSuccess,
  deleteOrderProcedures,
  deleteProcedure,
  deleteProcedureSuccess,
  loadProceduresSuccess,
  upsertProcedure,
  upsertProcedureSuccess
} from './procedure.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  tap,
  switchMap,
  withLatestFrom,
  catchError,
  filter,
  map,
  concatMap,
  distinctUntilChanged
} from 'rxjs/operators';
import { NotificationService } from '../../core.module';
import { of, zip } from 'rxjs';
import { setActiveClient } from '../../../features/clients/clients.actions';
import {
  ProcedureRecord,
  State
} from '../../../shared/procedures/procedures.model';

export const PROCEDURES_KEY = 'PROCEDURES';

@Injectable()
export class ProcedureEffects {
  add = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProcedure),
        switchMap((action) =>
          zip(this.proceduresService.add(action.procedure), of(action))
        ),
        catchError((e) => {
          console.error('addProcedure', { e });
          this.notificationService.error(e?.error);
          return of(null);
        }),
        filter((el) => el !== null),
        map(([procedure, { order }]) =>
          addProcedureSuccess({ procedure, order })
        ),
        tap(() => {
          this.notificationService.info('oniks.common.form.add-success');
        })
      ),
    { dispatch: true }
  );

  upsert = createEffect(
    () =>
      this.actions$.pipe(
        ofType(upsertProcedure),
        filter(({ procedure, order }) => !!procedure?.code && !!order?.id),
        switchMap((action) =>
          zip(this.proceduresService.update(action.procedure), of(action))
        ),
        catchError((e) => {
          console.error('upsertProcedure', { e });
          this.notificationService.error(e?.error || 'oniks.common.form.error');
          return of(null);
        }),
        filter((el) => el !== null),
        map(([, { order, procedure }]) =>
          upsertProcedureSuccess({
            procedure,
            order,
            triggerOrderUpsert: true
          })
        ),
        tap(() => {
          this.notificationService.info('oniks.common.form.save-success');
        })
      ),
    { dispatch: true }
  );

  delete = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteProcedure),
        concatMap((action) =>
          zip(
            of(action),
            this.proceduresService.delete(action.procedure),
            this.store.select(selectAllReminderRecords)
          )
        ),
        catchError((e) => {
          console.error('deleteProcedure', { e });
          this.notificationService.error(e?.error || 'oniks.common.form.error');
          return of(null);
        }),
        filter((el) => el !== null),
        map(([action, b, reminders]) => {
          const reminderToDelete = reminders.find(
            (reminder) =>
              reminder.pk === (action.procedure as ProcedureRecord).id
          );
          return deleteProcedureSuccess({
            reminder: reminderToDelete,
            order: action.order,
            triggerOrderUpsert: action.triggerOrderUpsert
          });
        })
      ),
    { dispatch: true }
  );

  deleteOrderProcedures = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteOrderProcedures),
        tap(({ procedures, order }) => {
          procedures.forEach((procedure) =>
            this.store.dispatch(
              deleteProcedure({ procedure, order, triggerOrderUpsert: false })
            )
          );
        })
      ),
    { dispatch: false }
  );

  getClientProcedures = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setActiveClient),
        withLatestFrom(this.store.select(selectShouldUpdatedClientProcedures)),
        tap(([action, shouldUpdate]) => console.log({ action, shouldUpdate })),
        filter(
          ([action, shouldUpdate]) => !!action.selectedClientId && shouldUpdate
        ),
        distinctUntilChanged(
          ([prev], [curr]) => prev.selectedClientId === curr.selectedClientId
        ),
        switchMap(([action]) =>
          this.proceduresService.get(action.selectedClientId)
        ),
        map((rs) => loadProceduresSuccess({ procedures: rs.Items }))
      ),
    { dispatch: true }
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private notificationService: NotificationService,
    private proceduresService: ProceduresService
  ) {}
}
