import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ConfirmComponent } from './confirm/confirm.component';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  default(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'default-notification-overlay'
    });
  }

  info(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'info-notification-overlay'
    });
  }

  success(message: string) {
    this.show(message, {
      duration: 2000,
      panelClass: 'success-notification-overlay'
    });
  }

  warn(message: string) {
    this.show(message, {
      duration: 2500,
      panelClass: 'warning-notification-overlay'
    });
  }

  error(message: string) {
    this.show(message, {
      duration: 3000,
      panelClass: 'error-notification-overlay'
    });
  }

  confirm(message: string): Observable<boolean> {
    const confirmDialogConfig = new MatDialogConfig();
    confirmDialogConfig.autoFocus = false;
    confirmDialogConfig.panelClass = 'confirm-dialog-container';
    confirmDialogConfig.width = '400px';
    return this.dialog
      .open(ConfirmComponent, {
        ...confirmDialogConfig,
        data: { message }
      })
      .afterClosed()
      .pipe(first());
  }

  private show(message: string, configuration: MatSnackBarConfig) {
    // Need to open snackBar from Angular zone to prevent issues with its position per
    // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
    this.zone.run(() =>
      this.snackBar.open(
        this.translateService.instant(message),
        null,
        configuration
      )
    );
  }
}
